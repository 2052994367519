import { toastr } from '~src/globals/toastr.ts'
import { $ } from '~src/globals/jquery.ts'

export const auth = {
  login: function (form, redirectUrl, useTwoFactor = true) {
    const twoFactor = new TwoFactorAuthentication(function (code, recoveryCode) {
      return $.request({
        method: 'POST',
        url: form.attr('action'),
        json: {
          ...form.serializeJSON(),
          code: code,
          recoveryCode: recoveryCode,
        },
        loadingButton: form.find('button[type=submit]'),
        withoutBaseUrl: true,
      })
        .then(function (res) {
          if (!res.error) {
            location.href = redirectUrl
            return
          }
          if (res.twoFactorRequired) {
            twoFactor.show(res)
            return
          }
          toastr.error(res.error)
        })
        .catch(function () {
          /* handled with global toast */
        })
    })
    twoFactor.login(useTwoFactor)
  },

  validateCode: function (action) {
    let actionUrl = action
    const twoFactor = new TwoFactorAuthentication((code) => {
      return $.request({
        method: 'POST',
        url: actionUrl,
        json: { '2fa-code': code },
        withoutBaseUrl: true,
      }).then(function (res) {
        if (res.error) {
          toastr.error(res.msg)
          return
        }

        twoFactor.hideLogin()

        switch (actionUrl) {
          case '/api/intern/company/settings/disable-two-factor-authentication':
            $('.tfa-buttons button').toggle()
            toastr.success(res.msg)
            $('#btn-show-recovery-codes').hide()
            break
          case '/api/intern/company/settings/get-recovery-codes':
          case '/api/intern/company/settings/regenerate-recovery-codes':
            $('#modal-recovery-codes-display-inner').html(res.html)
            $('#modal-recovery-codes-display').modal('show')
            break
          case '/api/intern/company/settings/check-2fa-code':
            location.href = '/intern/company/recovery-codes-pdf?code=' + code
            break
        }
      })
    })

    $(['data-2fa-submit-text-default']).show()
    $(['data-2fa-submit-text-login']).hide()
    twoFactor.init()
  },
}

function TwoFactorAuthentication(loginFn) {
  this.needsInit = false
  this.onSubmitCallback = loginFn

  this.modalInit = $('#modal-twofactor-init')
  this.modalLogin = $('#modal-twofactor-login')
  this.modalRecover = $('#modal-recover-2fa-account')
  this.modalRecoverForm = $('#modal-recover-2fa-account-form')
  this.modalRecoverSuccess = $('#modal-recover-2fa-success')

  this.input = new TwoFactorInput(document.getElementById('twoFactorInputContainer'), 6, this.submit.bind(this))
}

TwoFactorAuthentication.prototype.login = function (useTwoFactor = true) {
  if (useTwoFactor) {
    this.init()
  }
  this.onSubmitCallback()
}

TwoFactorAuthentication.prototype.init = function () {
  // Refactor TwoFactorInput to class. This is not easy, as this is also used for jQuery instance of event triggered element
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this

  $(document).ready(function () {
    self.hideRecoveryLink()

    if (TwoFactorAuthentication.initialized) {
      return
    }

    self.initializeEventListeners()
    TwoFactorAuthentication.initialized = true
    self.input.render()
  })
}

TwoFactorAuthentication.prototype.hideRecoveryLink = function () {
  var url = window.location.href
  if (url.includes('/admin-login') || url.includes('/intern')) {
    $('[data-link-2fa-recover]').hide()
  }
}

TwoFactorAuthentication.prototype.initializeEventListeners = function () {
  // Refactor TwoFactorInput to class. This is not easy, as this is also used for jQuery instance of event triggered element
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this

  this.modalInit.find('form').submit(function (event) {
    event.preventDefault()
    self.submit(true)
  })

  this.modalLogin.find('form').submit(function (event) {
    event.preventDefault()
    self.submit()
  })

  this.modalLogin.find('[data-link-2fa-recover]').click(function (event) {
    event.preventDefault()
    self.modalLogin.modal('hide')
    self.modalRecover.modal('show')
    self.modalRecover.find('#twofactor-recover-code').focus()
  })

  this.modalRecover.find('[data-link-2fa-recover-form]').click(function (event) {
    event.preventDefault()
    self.modalRecover.modal('hide')
    self.modalRecoverForm.modal('show')
    self.modalRecoverForm.find('#twofactor-recover-email').focus()
  })

  this.modalLogin.add(this.modalInit).on('hidden.bs.modal', function () {
    $(this).find('input').val('')
    self.setInitData('', '')
  })

  this.modalRecover.find('form').submit(function (event) {
    event.preventDefault()
    self.submitRecover($(this))
  })

  this.modalRecoverForm.find('form').submit(function (event) {
    event.preventDefault()
    self.submitRecoverForm($(this))
  })

  this.modalRecover.on('hidden.bs.modal', function () {
    self.resetForm(self.modalRecover.find('form'))
  })

  this.modalRecoverForm.on('hidden.bs.modal', function () {
    self.resetForm(self.modalRecoverForm.find('form'))
  })
}

TwoFactorAuthentication.prototype.submit = function (modalInit = false) {
  // Refactor TwoFactorInput to class. This is not easy, as this is also used for jQuery instance of event triggered element
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this
  this.toggleLoading(true)

  const code = modalInit ? encodeURIComponent(this.modalInit.find('input').val()) : this.input.getCode()

  this.onSubmitCallback(code).finally(function () {
    self.toggleLoading(false)
  })
}

TwoFactorAuthentication.prototype.submitRecoverForm = function (form) {
  var fileUploadIdentity = form.find('input[name=file-upload-identity]')
  var fileUploadOwnership = form.find('input[name=file-upload-ownership]')
  // Refactor TwoFactorInput to class. This is not easy, as this is also used for jQuery instance of event triggered element
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this

  var formData = new FormData()
  formData.append('email', form.find('input[name=email]').val())
  formData.append('identity', fileUploadIdentity[0].files[0])
  formData.append('ownership', fileUploadOwnership[0].files[0])

  void $.ajax({
    contentType: false,
    processData: false,
    type: 'POST',
    data: formData,
    url: form.attr('action'),
    beforeSend: function () {
      form.find('button[type=submit]').button('loading')
    },
    complete: function () {
      form.find('button[type=submit]').button('reset')
    },
    success: function (json) {
      if (!json.error) {
        self.resetForm(form)
        self.showRecoverySuccessModal()
      } else {
        toastr.error(json.msg)
      }
    },
  })
}

TwoFactorAuthentication.prototype.submitRecover = function () {
  this.onSubmitCallback(null, encodeURIComponent(this.modalRecover.find('input').val()))
}

TwoFactorAuthentication.prototype.resetForm = function (form) {
  form[0].reset()
}

TwoFactorAuthentication.initialized = false

TwoFactorAuthentication.prototype.show = function (data) {
  if (typeof data.twoFactorInitialized !== 'undefined') {
    this.needsInit = !data.twoFactorInitialized
  } else {
    this.needsInit = false
  }

  if (this.needsInit) {
    this.showInit(data.twoFactorQr, data.twoFactorSecret)
    return
  }

  this.showLogin()
}

TwoFactorAuthentication.prototype.toggleLoading = function (isLoading) {
  if (isLoading) {
    this.modalLogin.find('#btn-2fa-submit').button('loading')
  } else {
    this.modalLogin.find('#btn-2fa-submit').button('reset')
  }
}

TwoFactorAuthentication.prototype.showInit = function (qrCode, secret) {
  this.setInitData(qrCode, secret)
  this.modalInit.modal('show')
}

TwoFactorAuthentication.prototype.setInitData = function (qrCode, secret) {
  this.modalInit.find('#twofactor-init-qr').attr('src', qrCode)
  this.modalInit.find('#twofactor-init-qr').attr('alt', secret)
  this.modalInit.find('#twofactor-init-secret').html(secret)
}

TwoFactorAuthentication.prototype.hideInit = function () {
  this.modalInit.modal('hide')
}

TwoFactorAuthentication.prototype.showLogin = function () {
  this.modalLogin.modal('show')
  this.modalLogin.find('[data-idx="0"]').focus()
}

TwoFactorAuthentication.prototype.hideLogin = function () {
  this.modalLogin.modal('hide')
}

TwoFactorAuthentication.prototype.hide = function () {
  this.setInitData('', '')
  this.modalLogin.modal('hide')
  this.modalInit.modal('hide')
}

TwoFactorAuthentication.prototype.showRecoverySuccessModal = function () {
  this.modalRecoverForm.modal('hide')
  this.modalRecoverSuccess.modal('show')
}

TwoFactorAuthentication.prototype.showRecoverySuccessModal = function () {
  this.modalRecoverForm.modal('hide')
  this.modalRecoverSuccess.modal('show')
}

function TwoFactorInput(wrapper, totalDigits, callbackSubmit) {
  this.wrapper = wrapper
  this.totalDigits = totalDigits
  this.form = null
  this.inputs = []
  this.callbackSubmit = callbackSubmit
}

TwoFactorInput.prototype.focusElement = function (elem) {
  elem.focus()
  elem.select()
}

TwoFactorInput.prototype.render = function () {
  const inputsWrapper = this.createInputsWrapper(this.totalDigits)

  this.form = this.createForm()
  this.form.appendChild(inputsWrapper)
  this.wrapper.appendChild(this.form)

  this.addEvents()
}

TwoFactorInput.prototype.createForm = function () {
  const form = document.createElement('form')
  form.classList.add('code')
  form.autocomplete = 'off'
  form.autocorrect = 'off'
  form.autocapitalize = 'off'
  return form
}

TwoFactorInput.prototype.createInput = function (idx) {
  const input = document.createElement('input')
  input.classList.add('code__digit')
  input.type = 'number'
  input.maxlength = '1'
  input.ariaLabel = `Digit ${idx}`
  input.ariaRequired = 'true'
  input.dataset.idx = idx
  input.dataset.formType = 'other'
  return input
}

TwoFactorInput.prototype.createInputsWrapper = function (totalDigits) {
  const digitsWrapper = document.createElement('div')
  const fragment = document.createDocumentFragment()
  for (let i = 0; i < this.totalDigits; i++) {
    const input = this.createInput(i)
    this.inputs.push(input)
    fragment.appendChild(input)
  }
  digitsWrapper.classList.add('code__digits')
  digitsWrapper.style.setProperty('--total-digits', totalDigits)
  digitsWrapper.appendChild(fragment)
  return digitsWrapper
}

TwoFactorInput.prototype.addEvents = function () {
  this.form.addEventListener('input', this.checkInput.bind(this))
  this.form.addEventListener('click', this.focusInput.bind(this))
  this.form.addEventListener('paste', this.pasteCode.bind(this))
  this.form.addEventListener('keydown', this.pressKey.bind(this))
}

TwoFactorInput.prototype.checkInput = function (e) {
  const value = this.validateNumericInputs(e.target)
  if (value !== false) {
    e.target.value = value
    this.focusNextInput(e.target)
  } else {
    e.target.value = ''
  }
}

TwoFactorInput.prototype.validateNumericInputs = function (input) {
  if (isNaN(Number(input.value)) || input.value === '') {
    input.classList.add('invalid')
    return false
  }
  input.classList.remove('invalid')
  return input.value.length > 1 ? input.value[0] : input.value
}

TwoFactorInput.prototype.focusInput = function (e) {
  if (e.target.tagName.toUpperCase() === 'INPUT') {
    this.focusElement(e.target)
  }
}

TwoFactorInput.prototype.pasteCode = function (e) {
  // Refactor TwoFactorInput to class. This is not easy, as this is also used for jQuery instance of event triggered element
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this
  e.preventDefault()
  const copyCode = e.clipboardData.getData('text')
  for (let i = 0; i < this.inputs.length; i++) {
    this.inputs[i].value = copyCode[i] || ''
  }

  setTimeout(function () {
    for (let input of self.inputs) {
      self.focusElement(input)
    }

    self.callbackSubmit()
  }, 0)
}

TwoFactorInput.prototype.pressKey = function (e) {
  if (e.key === 'ArrowRight') {
    e.preventDefault()
    this.focusNextInput(e.target)
  }
  if (e.key === 'ArrowLeft') {
    e.preventDefault()
    this.focusPrevInput(e.target)
  }
  if (e.key === 'Backspace') {
    e.target.value = ''
    e.target.classList.add('invalid')

    if (e.target.dataset.idx === '0') {
      return
    }

    this.focusPrevInput(e.target)
  }
}

TwoFactorInput.prototype.focusNextInput = function (currentInput) {
  // Refactor TwoFactorInput to class. This is not easy, as this is also used for jQuery instance of event triggered element
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this
  if (currentInput.nextElementSibling) {
    this.focusElement(currentInput.nextElementSibling)
  } else {
    self.callbackSubmit()
  }
}

TwoFactorInput.prototype.focusPrevInput = function (currentInput) {
  if (currentInput.previousElementSibling) {
    this.focusElement(currentInput.previousElementSibling)
  }
}

TwoFactorInput.prototype.getCode = function () {
  let inputCode = ''
  for (let input of this.inputs) {
    inputCode += input.value
    input.blur()
  }

  return inputCode
}
